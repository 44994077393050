<script setup lang="ts">
import {useWindowSize} from '@vueuse/core'
import {baseNavigation} from "@/config/layout";
import Burger from "~/components/Layout/Header/Burger.vue";
import {storeToRefs} from "pinia";
import {useLayoutStore, useSettingsStore} from "@/store";
import {ref, watch} from 'vue'
import {onClickOutside} from '@vueuse/core'
import YandexTaxiButton from "~/components/YandexTaxiButton.vue";

const sidebar = ref<HTMLElement | null>(null);
const {showMobileNav} = storeToRefs(useLayoutStore());
const {closeMobileNav} = useLayoutStore();
const {width} = useWindowSize();

const {
  instagram
} = storeToRefs(useSettingsStore());

onClickOutside(sidebar, () => closeMobileNav());

watch(width, () => {
  closeMobileNav();
})

const showMobileNavBottom = ref<boolean>(false);
watch(showMobileNav, () => {
  if (showMobileNav.value) {
    setTimeout(() => {
      showMobileNavBottom.value = true;
    }, 300)
  }
});
</script>

<template>
  <div ref="sidebar">
    <nav class="hidden lg:block">
      <ul>
        <li
            v-for="(item, index) in baseNavigation"
            :class="item.to === '/booking' ? 'booking-link' : ''"
            :key="index"
        >
          <nuxt-link v-if="item.to" :to="item.to" :title="item.title">
            {{ item.title }}
          </nuxt-link>
          <a v-if="item.href" :href="item.href" :title="item.title" target="'_blank">
            {{ item.title }}
          </a>
        </li>
        <li v-if="instagram">
          <a :href="instagram" target="_blank" rel="nofollow" title="Instagram">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 26 26">
              <path stroke="currentColor" stroke-miterlimit="10" stroke-width="2"
                    d="M13 18a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"/>
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M18.5 1.5h-11a6 6 0 0 0-6 6v11a6 6 0 0 0 6 6h11a6 6 0 0 0 6-6v-11a6 6 0 0 0-6-6Z"/>
              <path fill="currentColor" d="M19.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/>
            </svg>
          </a>
        </li>
      </ul>
      <div class="pt-6 -translate-x-2 flex flex-col gap-1">
        <a
            href="https://registration.lo.cards/?hash=oJLHEk3hpkEZZdqA"
            target="_blank"
            class="bg-[#7aa1ee]/70 hover:bg-[#7aa1ee]/100 transition-all rounded-[6px] px-2 py-0.5 pt-1.5 text-[14px] uppercase">
          Карта лояльности 🎁
        </a>
        <span class="text-[13px] opacity-70 text-right">Дарим 500р на счет<br />Без установки приложения</span>
      </div>
    </nav>
    <Burger class="lg:hidden"/>
    <transition name="fade-slide-up" appear>
      <div v-if="showMobileNav" class="fixed top-0 left-0 h-full bg-transparent z-10 pt-16 pb-4">
        <nav class="h-full overflow-auto landscape:pr-5 px-5">
          <ul>
            <li
                v-for="(item, index) in baseNavigation"
                :key="index"
                :class="item.to === '/booking' ? 'booking-link' : ''"
                @click="closeMobileNav"
            >
              <nuxt-link v-if="item.to" :to="item.to" :title="item.title">
                {{ item.title }}
              </nuxt-link>
              <a v-if="item.href" :href="item.href" :title="item.title" target="'_blank" rel="nofollow">
                {{ item.title }}
              </a>
            </li>
            <li v-if="instagram">
              <a :href="instagram" target="_blank" rel="nofollow" title="Instagram">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 26 26">
                  <path stroke="currentColor" stroke-miterlimit="10" stroke-width="2"
                        d="M13 18a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"/>
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M18.5 1.5h-11a6 6 0 0 0-6 6v11a6 6 0 0 0 6 6h11a6 6 0 0 0 6-6v-11a6 6 0 0 0-6-6Z"/>
                  <path fill="currentColor" d="M19.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/>
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>

    </transition>

    <transition name="fade-slide-up">
      <div v-if="showMobileNav" class="w-full fixed bottom-0 left-0 right-0 mb-16 px-4 z-[1000]">
        <div class="flex flex-col gap-3">
          <div class="flex flex-col gap-1">
          <a
              href="https://registration.lo.cards/?hash=oJLHEk3hpkEZZdqA"
              target="_blank"
              class="w-fit flex items-center justify-center bg-[#7aa1ee]/80 hover:bg-[#7aa1ee]/100 transition-all rounded-[6px] px-2 px-2 pb-2 pt-2.5 leading-none text-sm">
            Карта лояльности 🎁
          </a>
          <span class="text-[13px] opacity-70 text-left hidden 390:block">Дарим 500р на счет<br />Без установки приложения</span>
          </div>
          <YandexTaxiButton class="hidden xxs:flex" target-self />
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.booking-link {
  @apply -translate-x-2;

  & a {
    @apply bg-white/10 hover:bg-white/20 rounded-[6px] px-2 py-0.5 pt-1.5 leading-none;

    &.router-link-active {
      @apply bg-white/20;
    }
  }
}

nav {
  & ul {
    @apply flex flex-col gap-3;
    & li {
      @apply text-lg;
      a {
        @apply text-white text-opacity-75 transition-all uppercase;
        &:hover {
          @apply text-white text-opacity-100;
          text-shadow: 0.5px 0 0 #212121;
        }

        &.router-link-active {
          @apply opacity-100 text-white;
          text-shadow: 0.5px 0 0 #212121;
        }

      }
    }
  }
}
</style>