import {useMenuStore} from "@/store";
import {storeToRefs} from "pinia";

const {
    baseMenu,
    wineMenu
} = storeToRefs(useMenuStore());
export const baseNavigation = computed(() => {
    return [
        {
            title: "Кухня",
            to: "/"
        },
        {
            title: "Атмосфера",
            to: "/atmosfera"
        },
        {
            title: "Меню",
            href: baseMenu.value || "/menu.pdf"
        },
        {
            title: "Вино и напитки",
            href: wineMenu.value || "/menu.pdf"
        },
        {
            title: "Акции",
            to: "/promo"
        },
        {
            title: "Банкеты",
            to: "/banket"
        },
        {
            title: "Афиша",
            to: "/events"
        },
        {
            title: "Бронирование",
            to: "/booking"
        },
        {
            title: "Контакты",
            to: "/contacts"
        }
    ]
})