<script setup lang="ts">
import {Header, Footer} from "@/components/Layout";
import {storeToRefs} from "pinia";
import {useLayoutStore, useSettingsStore, useSlidesStore, useMenuStore} from "@/store";
import {computed} from "vue";
import {useRoute} from "vue-router";

const {showMobileNav} = storeToRefs(useLayoutStore());

const {fetchSettings} = useSettingsStore();
const {fetchSlides} = useSlidesStore();
const {fetchMenu} = useMenuStore();
fetchSettings();
fetchMenu();
fetchSlides();

const route = useRoute()
const url = import.meta.env.m
const canonical = computed(() => {
  if (route.path === '/') {
    return url
  }
  return url + route.path
});
useHead({
  link: [{rel: 'canonical', href: canonical.value}]
});
</script>

<template>
  <div class="container font-body flex-1">
    <Header/>
    <main :class="{
      'duration-300 absolute w-full translate-x-[25%] opacity-0 scale-[0] rounded-2xl overflow-hidden pointer-events-none': showMobileNav,
      'relative lg:z-[1000]': !showMobileNav
    }"
          class="transition-all transform-gpu lg:transform-none">
      <slot/>
    </main>
    <Footer :class="$style.footer"/>
  </div>
</template>

<style lang="scss" module>
header {
  @apply fixed left-0 right-0 top-0 z-10;
}

.footer {
  @apply fixed bottom-0;
}

main {
  @apply lg:max-w-[calc(100%-400px)] h-full mx-auto;
}
</style>