<script setup lang="ts">
import YandexTaxiButton from "@/components/YandexTaxiButton.vue";
import {isLaptopOrDesktop} from "~/helpers/breakpoints";
import {storeToRefs} from 'pinia';
import {useLayoutStore, useSettingsStore} from '@/store';
import { useWindowSize } from '@vueuse/core'
const {
  phone, phoneHref, city, address, workTime, workDays
} = storeToRefs(useSettingsStore());
const {showMobileNav} = storeToRefs(useLayoutStore());
const { width, height } = useWindowSize()
</script>

<template>
  <footer>
    <div class="flex flex-col gap-3 w-fit">
      <div :class="width > 991 && height < 610 ? 'hidden' : 'hidden lg:block font-bold text-lg'">
        <a :href="`tel:${phoneHref}`">{{ phone }}</a>
      </div>
      <nuxt-link to="/contacts" :class="width > 991 && height < 668 ? 'hidden' : 'flex flex-col text-base opacity-75 hover:opacity-100 transition-all'">
        <span v-show="$route.meta.type === 'page-gallery' || isLaptopOrDesktop || showMobileNav">
            {{ address }}
        </span>
        <span class="hidden lg:block">{{ city }}</span>
      </nuxt-link>
      <div :class="width > 991 && height < 734 ? 'hidden' : 'hidden lg:flex flex-col text-base opacity-75'">
        <span>{{ workDays }}</span>
        <span>{{ workTime }}</span>
      </div>
      <YandexTaxiButton :class="width > 991 && height < 776 ? 'hidden' : 'hidden lg:block'"/>
    </div>
  </footer>
</template>

<style lang="scss" module>
footer {
  @apply flex items-start justify-center lg:justify-between ;
  @apply my-5 lg:my-10 w-full;
}
</style>