<script setup lang="ts">
import {Navigation} from "@/components/Layout";
import {storeToRefs} from 'pinia';
import {useLayoutStore, useSettingsStore} from '@/store'

const {
  phone, phoneHref
} = storeToRefs(useSettingsStore());
const {showMobileNav} = storeToRefs(useLayoutStore());
</script>

<template>
  <header>
    <!--    :class="{'bg-[#f4ecdf] md:bg-transparent bg-opacity-75 backdrop-blur-[4px] md:backdrop-blur-[0px]': $route.meta.type !== 'page-gallery'}"-->
    <div
        class="container px-4 lg:px-0 flex items-center md:items-start">
      <Navigation/>
      <a :href="`tel:${phoneHref}`" title="Позвонить"
         :class="$route.path !== '/booking'  && !showMobileNav ? 'mx-5' : 'ml-5 mr-auto'"
         class=" lg:hidden z-[1000] mx-5 w-7 h-7 rounded-full overflow-hidden flex items-center justify-center ">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                d="M21 16.477v2.71c.004 1.035-.928 1.9-1.974 1.806C10 21 3 13.935 3.008 4.969 2.913 3.93 3.774 3.001 4.808 3h2.715c.44-.004.865.151 1.198.437.947.812 1.556 3.57 1.322 4.667-.183.856-1.046 1.455-1.633 2.04a14.467 14.467 0 0 0 5.43 5.42c.587-.585 1.187-1.446 2.045-1.629 1.1-.234 3.877.376 4.686 1.329.287.338.44.77.428 1.213Z"/>
        </svg>
      </a>
      <div v-if="$route.path !== '/booking' && !showMobileNav" class="booking-link">
                        <nuxt-link to="/booking">Бронирование</nuxt-link>
          </div>
      <nuxt-link :class="{
        'scale-[2] duration-300 translate-y-10 -translate-x-10 pointer-events-none': showMobileNav
      }" class="w-[100px] translate-y-1 lg:w-[100px] xl:w-[220px] mr-[5px] lg:-translate-y-4 transition-all"
                 to="/" title="Ресторан-бар Образ Жизни">
        <nuxt-img src="/logo.svg" class="hidden lg:block" />
        <nuxt-img src="/logo-mob.svg" class="lg:hidden" />
      </nuxt-link>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.booking-link {
  @apply mr-auto translate-y-0.5 lg:hidden;
  & a {
    @apply text-white;
    @apply bg-white/10 hover:bg-white/20 text-white rounded-[6px] px-2 py-1 pt-1.5 leading-none;

    &.router-link-active {
      @apply bg-white/20;
    }
  }
}

header {
  & > div {
    @apply flex items-center lg:items-start justify-between w-full;
    @apply py-3 lg:py-10 w-full;
  }
}
</style>