<script lang="ts" setup>
import {storeToRefs} from 'pinia'
import {useLayoutStore} from '@/store'
const {showMobileNav} = storeToRefs(useLayoutStore())
const {toggleMobileNav} = useLayoutStore();
</script>

<template>
  <div id="burger" :class="{ active: showMobileNav }" @click="toggleMobileNav">
    <slot>
      <div class="button_background">
        <button type="button" class="burger-button w-10 h-10" title="Menu">
          <span class="burger-bar burger-bar--1" />
          <!--          <span class="burger-bar burger-bar&#45;&#45;2" />-->
          <span class="burger-bar burger-bar--3" />
        </button>
      </div>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
body.is-burger {
  overflow-y: hidden;
}

#burger {
  height: 32px;
  width: 32px;
  & button {
    cursor: pointer;
    position: relative;
    border: 0;
    background: transparent;
    z-index: 1001;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%;
    height: 100%;
    &:focus {
      outline: none;
    }
  }
}
.button_background {
  width: 100%;
  height: 100%;
}
.burger-bar {
  //@apply bg-[#212121];
  @apply bg-[#f8f8f8];
  position: absolute;
  width: 100%;
  top: 50%;
  right: 0;
  left: 0;
  height: 2px;
  border-radius: 2px;
  transition: transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1),
  opacity 0.15s cubic-bezier(0.165, 0.84, 0.44, 1),
  background-color 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  transform: translateY(-5px);
}

.burger-bar--2 {
  transform-origin: 0% 100%;
  width: 75%;
  margin-left: auto;
}

.burger-button:focus .burger-bar--2 {
  //transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
  //transform: scaleX(0.8);
}

.no-touchevents .burger-bar--2:hover {
  transform: scaleX(1);
}

.burger-bar--3 {
  transform: translateY(5px);
  width: 60%;
  margin-left: 0;
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
  width: 100%;
}
</style>